<template>
  <div class="user-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item :label="$t('searchForm.username')">
              <el-input
                v-model="searchFrom.userName"
                :placeholder="$t('searchForm.usernamePlaceholder')"
                :clearable="true"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchForm.loginName')">
              <el-input
                v-model="searchFrom.loginName"
                :placeholder="$t('searchForm.loginNamePlaceholder')"
                :clearable="true"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchForm.status')">
              <el-select v-model="searchFrom.userStatus">
                <el-option :label="$t('searchForm.all')" value="" />
                <el-option :label="$t('enum.accountStatus[0]')" value="0" />
                <el-option :label="$t('enum.accountStatus[1]')" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search">{{ $t('btn.search') }}</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="add">{{ $t('btn.add') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <div class="table-block">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f9fc', border: 'none', color: '#252526' }"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" :label="$t('table.order')" width="100" align="center" />
        <el-table-column prop="userName" :label="$t('table.username')" align="center" />
        <el-table-column prop="loginName" :label="$t('table.loginName')" align="center" />
        <el-table-column prop="phone" :label="$t('table.phone')" align="center" />
        <el-table-column prop="userStatus" :label="$t('table.status')" align="center">
          <template slot-scope="scope">
            <div :class="[scope.row.userStatus == 1 ? 'status-point-normal' : 'status-point-freeze']">
              {{ $t(`enum.accountStatus[${scope.row.userStatus}]`) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('table.createTime')" align="center" />
        <el-table-column prop="createBy" :label="$t('table.createBy')" align="center" />
        <el-table-column :label="$t('table.operate')" align="center" width="100px">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="success"
              v-if="scope.row.userStatus == 0"
              @click="switchStatus(scope.row.userId, 1)"
              >{{ $t('enum.accountStatus[1]') }}</el-link
            >
            <el-link :underline="false" type="danger" v-else @click="switchStatus(scope.row.userId, 0)">{{
              $t('enum.accountStatus[0]')
            }}</el-link>
            <el-divider direction="vertical" />
            <el-link :underline="false" type="primary" @click="edit(scope.row.userId)">{{ $t('btn.edit') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @changePage="changePage"
        :total="total"
        :page.sync="searchFrom.pageNo"
        :size.sync="searchFrom.pageSize"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      :show-close="false"
      class="page-dialog"
      :close-on-click-modal="false"
      @close="onClose"
    >
      <template slot="title">
        <h3 class="dialog-title">{{ isEdit ? $t('btn.edit') : $t('btn.add') }}</h3>
      </template>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-width="$i18n.locale === 'en' ? '145px' : '80px'"
        class="page-dialog-form"
      >
        <el-form-item :label="$t('dialog.userManagement.userName')" prop="userName">
          <el-input
            v-model="form.userName"
            :placeholder="$t('dialog.userManagement.userNameMsg')"
            :clearable="true"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.loginName')" prop="loginName">
          <el-input
            v-model="form.loginName"
            :placeholder="$t('dialog.userManagement.loginNameMsg')"
            :clearable="true"
            :disabled="isEdit"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.loginPassword')" prop="loginPassword">
          <el-input
            v-model="form.loginPassword"
            :placeholder="$t('dialog.userManagement.loginPasswordMsg')"
            :clearable="true"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.confirmPassword')" prop="confirmPassword">
          <el-input
            v-model="form.confirmPassword"
            :placeholder="$t('dialog.userManagement.confirmPasswordMsg')"
            :clearable="true"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.phone')" prop="phone">
          <el-input
            v-model="form.phone"
            :placeholder="$t('dialog.userManagement.phoneMsg')"
            :clearable="true"
            oninput="value=value.replace(/[^0-9-]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.organization')" prop="organizationIds">
          <organzition-tree
            :defaultCheckKey.sync="defaultCheckKey"
            :treeData="treeData"
            :multiple="false"
            @checkChange="checkChange"
          />
        </el-form-item>
        <el-form-item :label="$t('dialog.userManagement.role')" prop="roleIds">
          <el-select v-model="form.roleIds" multiple :placeholder="$t('dialog.userManagement.roleMsg')">
            <template v-if="roleOptions.length">
              <el-option v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value" />
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('btn.cancel') }}</el-button>
        <el-button type="primary" @click="onConfirm" :loading="loadingBtn">{{ $t('btn.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userList, userSave, userStatusUpdate, userPwdChange, userDetail, roleAll, organTree } from 'src/api/index'
import pagination from 'src/components/pagination'
import organzitionTree from 'src/components/organzitionTree.vue'
import { convertToFrontById } from 'src/utils/utils'
import { hex_md5 } from 'src/utils/md5'

export default {
  name: 'user-management',
  components: { pagination, organzitionTree },
  data() {
    const confirmPasswordValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('dialog.userManagement.confirmPasswordMsg')))
      } else if (this.form.loginPassword !== this.form.confirmPassword) {
        callback(new Error(this.$t('dialog.userManagement.confirmPasswordVerify')))
      } else {
        callback()
      }
    }
    const organValidate = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error(this.$t('dialog.userManagement.organizationMsg')))
      } else {
        callback()
      }
    }

    const roleValidate = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error(this.$t('dialog.userManagement.roleMsg')))
      } else {
        callback()
      }
    }

    return {
      searchFrom: {
        userName: '',
        loginName: '',
        userStatus: '',
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      total: 0,
      tableData: [],
      dialogVisible: false,
      form: {
        userId: '',
        userName: '',
        loginName: '',
        loginPassword: '',
        confirmPassword: '', //自定义
        phone: '',
        userStatus: 1,
        organizationIds: [],
        roleIds: [],
      },
      loadingBtn: false,
      isEdit: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      defaultCheckKey: [], //tree初始化选中项
      roleOptions: [],
      roleValidate,
      organValidate,
      confirmPasswordValidate,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    rules() {
      return {
        userName: [{ required: true, message: this.$t('dialog.userManagement.userNameMsg'), trigger: 'blur' }],
        loginName: [{ required: true, message: this.$t('dialog.userManagement.loginNameMsg'), trigger: 'blur' }],
        loginPassword: [
          { required: true, message: this.$t('dialog.userManagement.loginPasswordMsg'), trigger: 'blur' },
        ],
        confirmPassword: [{ required: true, validator: this.confirmPasswordValidate, trigger: 'blur' }],
        organizationIds: [
          {
            required: true,
            validator: this.organValidate,
            trigger: 'blur',
          },
        ],
        roleIds: [
          {
            required: true,
            validator: this.roleValidate,
            trigger: 'blur',
          },
        ],
      }
    },
  },
  methods: {
    getList() {
      this.loading = true
      userList(this.searchFrom).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },
    getAllRole() {
      roleAll().then(res => {
        if (res.length) {
          this.roleOptions = res.map(v => {
            return { label: v['roleName'], value: v['roleId'] }
          })
        }
      })
    },

    getOrganTree() {
      organTree().then(res => {
        this.defaultCheckKey = this.isEdit ? this.form.organizationIds : []
        this.treeData = convertToFrontById(res)
      })
    },
    search() {
      this.searchFrom = { ...this.searchFrom, pageNo: 1 }
      this.getList()
    },
    async add() {
      this.getOrganTree()
      this.getAllRole()
      this.dialogVisible = true
    },

    checkChange(organObj) {
      // this.form['organizationIds'] = checkList.map(v => v.id)
      this.form['organizationIds'] = [organObj['id']]
    },

    changePage(pageParams) {
      const { pageNo, pageSize } = pageParams
      this.searchFrom = { ...this.searchFrom, pageNo, pageSize }
      this.getList()
    },
    onClose() {
      this.$refs.form.resetFields()
      this.loadingBtn = false
      this.isEdit = false
      this.defaultCheckKey = []
      this.treeData = []
      this.form = {
        userId: '',
        userName: '',
        loginName: '',
        loginPassword: '',
        confirmPassword: '',
        phone: '',
        userStatus: 1,
        organizationIds: [],
        roleIds: [],
      }
    },
    onConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingBtn = true
          let form = {}
          if (!this.isEdit) {
            form = { ...this.form, loginPassword: hex_md5(this.form['loginPassword']) }
          } else {
            form = this.form
          }
          userSave(form)
            .then(res => {
              this.dialogVisible = false
              this.loadingBtn = false
              this.isEdit = false
              this.$message({
                message: this.$t('message.saveSuccess'),
                type: 'success',
              })
              this.getList()
            })
            .catch(err => {
              this.loadingBtn = false
            })
        } else {
          return false
        }
      })
    },
    edit(userId) {
      this.isEdit = true
      userDetail({ userId }).then(res => {
        const { userId, userName, loginName, loginPassword, phone, organizationList, roleList, userStatus } = res
        const organizationIds = organizationList.map(v => v['organizationId'])
        const roleIds = roleList.map(v => v['roleId'])
        this.form = {
          ...this.form,
          userId,
          userName,
          loginName,
          loginPassword,
          confirmPassword: loginPassword,
          phone,
          organizationIds,
          roleIds,
          userStatus,
        }
        this.getOrganTree()
        this.getAllRole()
        this.dialogVisible = true
      })
    },

    switchStatus(userId, userStatus) {
      if (userStatus === 0) {
        this.$confirm(this.$t('message.disabledTip'), this.$t('message.warning'), {
          confirmButtonText: this.$t('btn.confirm'),
          cancelButtonText: this.$t('btn.cancel'),
          type: 'warning',
        })
          .then(() => {
            userStatusUpdate({ userId, userStatus }).then(res => {
              this.$message({
                type: 'success',
                message: this.$t('message.saveSuccess'),
              })
              this.getList()
            })
          })
          .catch(() => {})
        return
      }
      if (userStatus === 1) {
        userStatusUpdate({ userId, userStatus }).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.saveSuccess'),
          })
          this.getList()
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.user-management-page {
  background: transparent;
}
</style>
